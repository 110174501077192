.App {
  text-align: center;
}

.navbarStyle {
  padding: .5rem 1rem !important
}

.col-md-6 {
  float: left;
  table-layout: fixed;
  margin: .2rem;
  width: auto;
}

.runLogOutingHeader {
  background-color: slategray;
}

.runLogOutings {
  width: 100%;
  height: 80vh;
  margin: 2px;
  position: relative;
  overflow-y: scroll;
}

.runLog-button {
  width: 100%;
  margin: 1px;
}

.runLog {
  margin: 0px;
  padding: 2px;
  width: 12%;
}

.runLogOutingNum {
  width: 10%;
  padding: 0;
  margin: 0;
  font-size: 20px;
  border-right: 1.5px solid darkgray;
  margin-right: 3px;
  margin-left: -12px;
}


.runLogLapInfo {
  margin-left: 3px;
  width: 40%;
  font-size: small;
  align-self: center;
}

.runLogOutingName {
  width: 88%;
  font-size: small;
  text-align: right;
  align-items: center;
}


.runLogTeam {
  width: 30%;
  font-size: 20px;
  border-left: 1.5px solid darkgray;
  text-align: center;
}

.runLogDB {
  width: 30%;
  font-size: 18px;
  text-align: center;
  border-right: 1.5px solid darkgray;
}

.runLogSession {
  width: 40%;
  font-size: 16px;
  text-align: center;
  flex-shrink: 1;
}

.runLogEvent {
  width: 50%;
  font-size: 14px;
  text-align: center;
  align-self: center;
}

.errorCount {
  font-size: 11px;
  text-align: center;
  align-self: center;
  margin-right: 10px;
}

.runLogButtonEventContainer {
  display: flex;
  flex-direction: row;
}

.runLogCheck {
  text-align: center;
  align-self: center;
  align-items: center;
  font-size: small;
  width: 55px;
  display: flex;
}


.runLogCheckRaceOnly {
  text-align: left;
  align-self: center;
  font-size: small;
  width: 25%;
  margin-top: 2.5%;
}

.runLogFilterEvents {
  width: 70%;
  margin: 4px 0px;
  height: 80%;
  text-align: center;
  margin-left: auto;
}

.runLogSettingsButton {
  margin-left: auto;
  padding: 0px;
  background-color: transparent;
  border-color: transparent;
  color: black;
}

.runLogOutingNotes {
  white-space: pre-line;
  font-size: small;
}

.setheight {
  height: 100%;
}

.printArea {
  margin: 25px;
}

.tireInfoQuads {
  margin-left: auto;
  font-size: '0.9vw';
  padding: 1.5%;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tireCornerLeft {
  background-color: dimgray;
  padding: 1.5%;
  margin: 5px;
  font-size: small;
  text-align: right;
}

.tireCornerRight {
  background-color: dimgray;
  padding: 1.5%;
  margin: 5px;
  font-size: small;
  text-align: left;
}

.tireGridContainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
}